var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myI18n)?_c('div',{staticClass:"my-signin"},[_c('div',{staticClass:"wrapper--no-padding"},[(_vm.myI18n.loginPage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"intro col-xxxs-20 col-m-10 col-xl-10 p-xxl"},[(_vm.myI18n.loginPage.picture)?_c('img',{attrs:{"alt":_vm.myI18n.loginPage.picture.alt || _vm.myI18n.loginPage.picture.title,"src":_vm.apiDomain + _vm.myI18n.loginPage.picture.url}}):_vm._e(),_c('h1',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.myI18n.loginPage.title)+" ")]),_c('div',{staticClass:"lead mb-l"},[_vm._v(" "+_vm._s(_vm.myI18n.loginPage.subtitle)+" ")]),(_vm.myI18n.loginPage.advantages)?[(_vm.myI18n.loginPage.advantages.title)?_c('strong',{staticClass:"intro__advantages mb-xxs h5"},[_vm._v(" "+_vm._s(_vm.myI18n.loginPage.advantages.title)+" ")]):_vm._e(),(
              _vm.myI18n.loginPage.advantages.items &&
              _vm.myI18n.loginPage.advantages.items.length > 0
            )?_c('ul',{staticClass:"check-list"},_vm._l((_vm.myI18n.loginPage.advantages.items),function(item,index){return _c('li',{key:index,staticClass:"check-list__item small mb-xxs intro__list__item"},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e()]:_vm._e()],2),(_vm.i18n)?_c('div',{staticClass:"actions"},[_c('div',{staticClass:"actions__login pt-xxl pb-xxl",class:{ action__login__connected: _vm.isLogged }},[_c('strong',{staticClass:"h4 mb-xs"},[_vm._v(" "+_vm._s(_vm.myI18n.loginPage.connexion.title)+" ")]),_c('CustomLink',{staticClass:"mb-xs",attrs:{"content":{
              label: _vm.myI18n.loginPage.connexion.labelButton,
              icon: {
                size: '0 0 24 24',
                name: 'arrows-large-right',
              },
            },"modifiers":['btn', 'icon', 'arrow'],"type":'button'},on:{"btnClick":_vm.signIn}}),_c('br'),_c('CustomLink',{staticClass:"link",attrs:{"content":{
              label: _vm.myI18n.loginPage.connexion.labelLink,
              to: { name: 'MyReset' },
            },"modifiers":['underline', 'dark', 'link']}})],1),(!_vm.isLogged)?_c('div',{staticClass:"actions__cta pt-l pb-l"},[_c('strong',{staticClass:"h4 mb-xs"},[_vm._v(" "+_vm._s(_vm.myI18n.loginPage.creation.title)+" ")]),_c('CustomLink',{staticClass:"mb-xs",attrs:{"content":{
              label: _vm.myI18n.loginPage.creation.labelButton,
              to: { name: 'MySignup' },
              icon: {
                size: '0 0 24 24',
                name: 'arrows-large-right',
              },
            },"modifiers":['btn', 'icon', 'arrow']}}),_c('CustomLink',{staticClass:"link",attrs:{"content":{
              label: _vm.myI18n.loginPage.creation.labelLink,
              to: { name: 'MyPpp' },
            },"modifiers":['underline', 'link']}})],1):_vm._e()]):_vm._e()]):_vm._e()])]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }