










































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import auth from '@/composables/auth'
import * as sso from '@/composables/sso'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  components: {
    CustomLink,
  },
  name: 'my-signin',
  setup(_props, ctx) {
    const { $route, $router } = ctx.root
    const { VUE_APP_TASK } = process.env

    const content = ref<Record<string, any>>()
    const { i18n } = useState('my', ['i18n'])
    const items = ref({})
    const intro = ref()
    const { lang } = ctx.root.$route.params
    const myState = {
      ...useState('my', ['i18n', 'smartData']),
    }
    const { fetchI18nOnly } = useActions('my', ['fetchI18nOnly'])
    const signIn = () => {
      let cb: string | undefined
      const { from } = $route.query

      if (from === 'ppp') {
        cb = '/ppp/success/'
      } else {
        cb = '/dashboard/'
      }
      if (VUE_APP_TASK === 'serve') {
        sso.authorize(cb)
      } else {
        sso.authorize(`/${lang}${cb}`)
      }
    }
    const { signOut } = auth

    onBeforeMount(() => {
      fetchI18nOnly().then(resp => {
        if (i18n.value.login.fields.intro) {
          items.value = [
            i18n.value.login.fields.intro.avantages.index,
            i18n.value.login.fields.intro.avantages.racc,
            i18n.value.login.fields.intro.avantages.perso,
            i18n.value.login.fields.intro.avantages.ppp,
          ]
        }
      })
    })

    onMounted(() => {
      const { route: loginRoute } = $router.resolve('/login')
    })

    return {
      content,
      i18n,
      myI18n: myState.i18n,
      intro,
      isLogged: auth.isLogged,
      signIn,
      signOut,
      lang,
      items,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
